@import './reset';
@import './themes';

@include font-face();

#app {
  width: $app-width;
  height: $app-height;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: contain;
  &.nob-theme {
    background-image: url('./../images/backgrounds/#{$first-bg}');

    .appDetailsView #waveBack {
      background-image: url('../../assets/images/backgrounds/background-store-detail.png');
    }
  }
  &.default-theme {
    background-image: url('./../images/backgrounds/Store.jpg');

    #Header #branding {
      #logo {
        background-image: url('../../assets/images/logo-default.png');
      }
      #tvStore {
        background: none;
      }
    }

    .appDetailsView #waveBack {
      background-image: url('../../assets/images/backgrounds/Store-Detail.png');
    }
  }
}

.view {
  width: $app-width;
  height: $app-height;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  color: #ffffff;
  font-size: toRem(40);
}
